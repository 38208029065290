<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">
            <v-row class="ma-3">
                <v-col cols="12">
                    <!-- <v-text-field
                        v-model="searchKeyword"
                        solo
                        style="float:right;"
                        filled
                        label="Search Users"
                        append-icon="mdi-magnify"
                        dense
                        @keydown="search"
                        @click:append="search"
                        hint="Press ENTER or click the 🔍 to search"
                    ></v-text-field> -->
                </v-col>
            </v-row>
            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="users"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.name`]="{item}">
                                <div class="ph-nonex-sender-container">
                                <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item.name)">
                                    <span class="white--text text-body-2">{{ avatarInitial(item.name) }}</span>
                                </v-avatar>
                                <p class="ph-nonex-table-names-text">{{item.name}}</p>
                                </div>
                            </template>
                            <template v-slot:[`item.is_active`]="{item}">
                                <v-switch
                                    v-model="item.is_active"
                                    inset
                                    @change="toggleActive(item, 'active')"
                                ></v-switch>
                            </template>

                            <!-- Approver -->
                            <template v-slot:[`item.is_evaluation_committee`]="{item}">
                                <v-switch
                                    v-if="item.user_role == 'approver' || item.user_role == 'encoder' "
                                    v-model="item.is_evaluation_committee"
                                    inset
                                    @change="toggleList(item, 'Evaluation Committee')"
                                ></v-switch>
                                <h5 v-else>Approver/Encoder Only</h5>
                            </template>
                            <template v-slot:[`item.is_president`]="{item}">
                                <v-switch
                                    v-if="item.user_role == 'approver'"
                                    v-model="item.is_president"
                                    inset
                                    @change="toggleList(item, 'President')"
                                ></v-switch>
                                <h5 v-else>Approver Only</h5>
                            </template>
                            <template v-slot:[`item.is_onboarding_committee`]="{item}">
                                <v-switch
                                    v-if="item.user_role == 'approver'"
                                    v-model="item.is_onboarding_committee"
                                    inset
                                    @change="toggleList(item, 'Onboarding Committee')"
                                ></v-switch>
                                <h5 v-else>Approver Only</h5>
                            </template>
                            <template v-slot:[`item.is_validation_committee`]="{item}">
                                <v-switch
                                    v-if="item.user_role == 'approver'"
                                    v-model="item.is_validation_committee"
                                    inset
                                    @change="toggleList(item, 'Validation Committee')"
                                ></v-switch>
                                <h5 v-else>Approver Only</h5>
                            </template>
                            <template v-slot:[`item.is_oh_approver`]="{item}">
                                <v-switch
                                    v-if="item.user_role == 'approver'"
                                    v-model="item.is_oh_approver"
                                    inset
                                    @change="toggleList(item, 'Onboarding Head')"
                                ></v-switch>
                                <h5 v-else>Approver Only</h5>
                            </template>
                            <template v-slot:[`item.is_det_compliance_approver`]="{item}">
                                <v-switch
                                    v-if="item.user_role == 'approver'"
                                    v-model="item.is_det_compliance_approver"
                                    inset
                                    @change="toggleList(item, 'Compliance Approver')"
                                ></v-switch>
                                <h5 v-else>Approver Only</h5>
                            </template>

                            <!-- Encoder -->
                            <template v-slot:[`item.is_det_encoder`]="{item}">
                                <v-switch
                                    v-if="item.user_role == 'encoder'"
                                    v-model="item.is_det_encoder"
                                    inset
                                    @change="toggleList(item, 'Det Encoder')"
                                ></v-switch>
                                <h5 v-else>Encoder Only</h5>
                            </template>

                            <!-- Admin -->
                            <template v-slot:[`item.is_avp`]="{item}">
                                <v-switch
                                    v-if="item.user_role == 'admin'"
                                    v-model="item.is_avp"
                                    inset
                                    @change="toggleList(item, 'AVP Encoder')"
                                ></v-switch>
                                <h5 v-else>Admin Only</h5>
                            </template>
                            <template v-slot:[`item.is_control_committee`]="{item}">
                                <v-switch
                                    v-model="item.is_control_committee"
                                    inset
                                    @change="toggleList(item, 'Control Committee')"
                                ></v-switch>
                            </template>
                            <template v-slot:[`item.is_sas_committee`]="{item}">
                                <v-switch
                                    v-if="item.user_role == 'admin'"
                                    v-model="item.is_sas_committee"
                                    inset
                                    @change="toggleList(item, 'SAS Committee')"
                                ></v-switch>
                                <h5 v-else>Admin Only</h5>
                            </template>
                            <template v-slot:[`item.is_finance_committee`]="{item}">
                                <v-switch
                                    v-model="item.is_finance_committee"
                                    inset
                                    @change="toggleList(item, 'Finance Committee')"
                                ></v-switch>
                            </template>
                            
                            <template v-slot:[`item.user_role`]="{item}">
                                <v-col>
                                    <v-select
                                        :items="roles"
                                        v-model="item.user_role"
                                        @change="toggleRole(item)"
                                    ></v-select>
                                </v-col>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="ma-3" v-if="currUser.email == 'vincent.besa@petnet.com.ph' || currUser.email == 'keanu.orig@petnet.com.ph'">
                <v-col :cols="5">
                    <v-text-field
                        label="Code"
                        hide-details="auto"
                        dense
                        v-model="code"
                        outlined
                        type="number"
                        hide-spin-buttons
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="1">
                    <v-btn
                        v-if="code"
                        color="success"
                        @click="testCode"
                        >
                        <b>Check</b>
                    </v-btn>
                    <v-btn
                        v-else
                        disabled
                        color="success"
                        >
                        <b>Check</b>
                    </v-btn>
                </v-col>
            </v-row>

            <v-dialog
                v-model="statusDialog"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p style="font-size: 15px;">Are you sure you want to {{actionStatus}} user <b>{{selectedUser.name}}</b>?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelActive()"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmActive()"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="onboardingDialog"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p style="font-size: 15px;">Are you sure you want to  {{listStatus}} user <b>{{selectedUser.name}}</b> <span v-if="listStatus === 'add'"> to the </span><span v-else> from the </span> <b> {{list}}</b>?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelList('is_onboarding_committee')"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmList('onboarding', listStatus)"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="evaluationDialog"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p style="font-size: 15px;">Are you sure you want to {{listStatus}} user <b>{{selectedUser.name}}</b> <span v-if="listStatus === 'add'"> to the </span> <span v-else> from the </span><b> {{list}}</b>?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelList('is_evaluation_committee')"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmList('evaluation', listStatus)"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="avpDialog"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p style="font-size: 15px;">Are you sure you want to {{listStatus}} user <b>{{selectedUser.name}}</b> <span v-if="listStatus === 'add'"> to the </span> <span v-else> from the </span><b> {{list}}</b>?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelList('is_avp')"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmList('avp', listStatus)"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="presidentDialog"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p v-if="listStatus === 'add'" style="font-size: 15px;">Are you sure you want to set user <b>{{selectedUser.name}}</b> as President?</p>
                        <p v-else style="font-size: 15px;">Are you sure you want to remove user <b>{{selectedUser.name}}</b> from being President?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelList('is_president')"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmList('president', listStatus)"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="validationDialog"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p v-if="listStatus === 'add'" style="font-size: 15px;">Are you sure you want to set user <b>{{selectedUser.name}}</b> as Validation?</p>
                        <p v-else style="font-size: 15px;">Are you sure you want to remove user <b>{{selectedUser.name}}</b> from being Validation?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelList('is_validation_committee')"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmList('validation', listStatus)"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="detEncoderDialog"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p v-if="listStatus === 'add'" style="font-size: 15px;">Are you sure you want to set user <b>{{selectedUser.name}}</b> as Det Encoder?</p>
                        <p v-else style="font-size: 15px;">Are you sure you want to remove user <b>{{selectedUser.name}}</b> from being Det Encoder?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelList('is_det_encoder')"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmList('det_encoder', listStatus)"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="complianceDialog"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p v-if="listStatus === 'add'" style="font-size: 15px;">Are you sure you want to set user <b>{{selectedUser.name}}</b> as Compliance Approver?</p>
                        <p v-else style="font-size: 15px;">Are you sure you want to remove user <b>{{selectedUser.name}}</b> from being Compliance Approver?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelList('is_det_compliance_approver')"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmList('compliance', listStatus)"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog
                v-model="roleDialog"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p style="font-size: 15px;">Are you sure you want to change user <b>{{selectedUser.name}}</b>'s role to {{selectedRole}}?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelRole()"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmRole()"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="controlDialog"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p v-if="listStatus === 'add'" style="font-size: 15px;">Are you sure you want to set user <b>{{selectedUser.name}}</b> as Control Committee?</p>
                        <p v-else style="font-size: 15px;">Are you sure you want to remove user <b>{{selectedUser.name}}</b> from being Control Committee?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelList('is_control_committee')"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmList('is_control_committee', listStatus)"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <v-dialog
                v-model="sasDialog"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p v-if="listStatus === 'add'" style="font-size: 15px;">Are you sure you want to set user <b>{{selectedUser.name}}</b> as SOS Committee?</p>
                        <p v-else style="font-size: 15px;">Are you sure you want to remove user <b>{{selectedUser.name}}</b> from being SOS Committee?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelList('is_sas_committee')"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmList('is_sas_committee', listStatus)"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="ohDialog"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p v-if="listStatus === 'add'" style="font-size: 15px;">Are you sure you want to set user <b>{{selectedUser.name}}</b> as Onboarding Head?</p>
                        <p v-else style="font-size: 15px;">Are you sure you want to remove user <b>{{selectedUser.name}}</b> from being Onboarding Head?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelList('is_oh_approver')"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmList('is_oh_approver', listStatus)"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="financeDialog"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <p v-if="listStatus === 'add'" style="font-size: 15px;">Are you sure you want to set user <b>{{selectedUser.name}}</b> as Finance Committee?</p>
                        <p v-else style="font-size: 15px;">Are you sure you want to remove user <b>{{selectedUser.name}}</b> from being Finance Committee?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelList('is_finance_committee')"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmList('is_finance_committee', listStatus)"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Users',
  data () {
    return {
        search: '',
        roleDialog: false,
        list: '',
        listStatus: '',
        selectedUser: '',
        selectedRole: '',
        actionStatus: '',
        statusDialog: false,
        searchKeyword: '',
        onboardingDialog: false,
        evaluationDialog: false,
        avpDialog: false,
        presidentDialog: false,
        validationDialog: false,
        detEncoderDialog: false,
        complianceDialog: false,
        controlDialog: false,
        sasDialog: false,
        ohDialog: false,
        financeDialog: false,
        roles: [
            {
                text: 'Admin',
                value: 'admin'
            },
            {
                text: 'Super Admin',
                value: 'super_admin'
            },
            {
                text: 'Approver',
                value: 'approver'
            },
            {
                text: 'Encoder',
                value: 'encoder'
            },
            {
                text: 'Auditor',
                value: 'auditor'
            },
        ],
        headers: [
            {
                text: 'Name',
                sortable: true,
                value: 'name'
            },
            {
                text: 'Email',
                sortable: true,
                value: 'email'
            },
            {
                text: 'Role',
                sortable: false,
                value: 'user_role'
            },
            {
                text: 'Evaluation Committee',
                sortable: false,
                value: 'is_evaluation_committee'
            },
            {
                text: 'President',
                sortable: false,
                value: 'is_president'
            },
            {
                text: 'Onboarding Committee',
                sortable: false,
                value: 'is_onboarding_committee'
            },
            {
                text: 'Validation Committee',
                sortable: false,
                value: 'is_validation_committee'
            },
            {
                text: 'Onboarding Head',
                sortable: false,
                value: 'is_oh_approver'
            },
            {
                text: 'Compliance Approver',
                sortable: false,
                value: 'is_det_compliance_approver'
            },
            {
                text: 'Det Encoder',
                sortable: false,
                value: 'is_det_encoder'
            },
            {
                text: 'AVP Encoder',
                sortable: false,
                value: 'is_avp'
            },
            {
                text: 'Control Committee',
                sortable: false,
                value: 'is_control_committee'
            },
            {
                text: 'SOS Committee',
                sortable: false,
                value: 'is_sas_committee'
            },
            {
                text: 'Finance Committee',
                sortable: false,
                value: 'is_finance_committee'
            },
            {
                text: 'Active',
                sortable: false,
                value: 'is_active'
            }
        ],
        users: [],
        usersOrig: [],
        code: ''
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  async created () {
        const res = await this.$store.dispatch('users/doSearchUsers')
        this.users = res.data.result
        this.usersOrig = JSON.parse(JSON.stringify(res.data.result))
        console.log(this.users)
        console.log(this.usersOrig)
    },
  methods: {
    async testCode(){

        const payload = {
            code : this.code,
            message: 'Status Code Valid',
            error: 'N/A'
        }

        const res = await this.$store.dispatch('salesProspecting/doGetStatusCode', payload)
        if (res.status){
            this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.data,'messagetype':'success'});
            this.code = ''
        } else {
            console.log(res)
            this.$store.dispatch('app/setSnackBar',{'status': true,'message': res,'messagetype':'error'});
            this.code = ''
        }
    },
    async confirmList (list, action) {
        console.log(action)
        if (list === 'onboarding') {
            this.onboardingDialog = false
            if(action === 'add'){
                const res = await this.$store.dispatch('users/doAddToOnboardingCommittee', {email: this.selectedUser.email})
                console.log(res)
            } else {
                const res = await this.$store.dispatch('users/doRemoveFromOnboardingCommittee', this.selectedUser.id)
                console.log(res)
            }      
        } else if (list === 'evaluation') {
            this.evaluationDialog = false
            if(action === 'add'){
                const res = await this.$store.dispatch('users/doAddToEvaluationCommittee', {email: this.selectedUser.email})
                console.log(res)
            } else {
                const res = await this.$store.dispatch('users/doRemoveFromEvaluationCommittee', this.selectedUser.id)
                console.log(res)
            }
        } else if (list === 'avp') {
            this.avpDialog = false
            console.log('AVP')
            if(action === 'add'){
                const res = await this.$store.dispatch('users/doAddToAvpEncoder', {email: this.selectedUser.email})
                console.log(res)
            } else {
                const res = await this.$store.dispatch('users/doRemoveFromAvpEncoder', this.selectedUser.id)
                console.log(res)
            }
        } else if (list === 'president') {
            this.presidentDialog = false
            if(action === 'add'){
                const res = await this.$store.dispatch('users/doAddAsPresident', {email: this.selectedUser.email})
                console.log(res)
            } else {
                const res = await this.$store.dispatch('users/doRemoveAsPresident', this.selectedUser.id)
                console.log(res)
            }
        }  else if (list === 'validation') {
            this.validationDialog = false
            if(action === 'add'){
                const res = await this.$store.dispatch('users/doAddAsValidation', {email: this.selectedUser.email})
                console.log(res)
            } else {
                const res = await this.$store.dispatch('users/doRemoveAsValidation', this.selectedUser.id)
                console.log(res)
            }
        }  else if (list === 'det_encoder') {
            this.detEncoderDialog = false
            if(action === 'add'){
                const res = await this.$store.dispatch('users/doAddAsDetEncoder', {email: this.selectedUser.email})
                console.log(res)
            } else {
                const res = await this.$store.dispatch('users/doRemoveDetEncoder', this.selectedUser.id)
                console.log(res)
            }
        }   else if (list === 'compliance') {
            this.complianceDialog = false
            if(action === 'add'){
                const res = await this.$store.dispatch('users/doAddAsCompliance', {email: this.selectedUser.email})
                console.log(res)
            } else {
                const res = await this.$store.dispatch('users/doRemoveCompliance', this.selectedUser.id)
                console.log(res)
            }
        }   else if (list === 'is_control_committee') {
            this.controlDialog = false
            if(action === 'add'){
                const res = await this.$store.dispatch('users/doAddAsControlCommittee', {email: this.selectedUser.email})
                console.log(res)
            } else {
                const res = await this.$store.dispatch('users/doRemoveAsControlCommittee', this.selectedUser.id)
                console.log(res)
            }
        }   else if (list === 'is_sas_committee') {
            this.sasDialog = false
            if(action === 'add'){
                const res = await this.$store.dispatch('users/doAddAsSasCommittee', {email: this.selectedUser.email})
                console.log(res)
            } else {
                const res = await this.$store.dispatch('users/doRemoveAsSasCommittee', this.selectedUser.id)
                console.log(res)
            }
        } else if (list === 'is_oh_approver') {
            this.ohDialog = false
            if(action === 'add'){
                const res = await this.$store.dispatch('users/doAddAsOhHead', {email: this.selectedUser.email})
                console.log(res)
            } else {
                const res = await this.$store.dispatch('users/doRemoveAsOhHead', this.selectedUser.id)
                console.log(res)
            }
        } else if (list === 'is_finance_committee') {
            this.financeDialog = false
            if(action === 'add'){
                const res = await this.$store.dispatch('users/doAddAsFinanceCommittee', {email: this.selectedUser.email})
                console.log(res)
            } else {
                const res = await this.$store.dispatch('users/doRemoveFinanceCommittee', this.selectedUser.id)
                console.log(res)
            }
        }
        
        this.selectedUser = ''
    },
    async confirmRole () {
        this.roleDialog = false
        const res = await this.$store.dispatch('users/doUpdateRole', {user_role: this.selectedUser.user_role, id: this.selectedUser.id})
        this.selectedUser = ''
        console.log(res)
        if (res.status === 200){
            location.reload()
        }
    },
    async confirmActive () {
        this.statusDialog = false
        const res = await this.$store.dispatch('users/doToggleStatus', this.selectedUser.id)
        this.selectedUser = ''
        console.log(res)
    },
    toggleList (item, list) {
        console.log(item)
        if (list === 'Onboarding Committee') {
            if(item.is_onboarding_committee){
                this.listStatus = 'add'
            } else {
                this.listStatus = 'remove'
            }
            this.onboardingDialog = true
        } else if (list === 'Evaluation Committee') {
            if(item.is_evaluation_committee){
                this.listStatus = 'add'
            } else {
                this.listStatus = 'remove'
            }
            this.evaluationDialog = true
        } else if (list === 'AVP Encoder') {
            if(item.is_avp){
                this.listStatus = 'add'
            } else {
                this.listStatus = 'remove'
            }
            this.avpDialog = true
        }  
        else if (list === 'President') {
            if(item.is_president){
                this.listStatus = 'add'
            } else {
                this.listStatus = 'remove'
            }
            this.presidentDialog = true
        } else if (list === 'Validation Committee') {
            if(item.is_validation_committee){
                this.listStatus = 'add'
            } else {
                this.listStatus = 'remove'
            }
            this.validationDialog = true
        } else if (list === 'Det Encoder') {
            if(item.is_det_encoder){
                this.listStatus = 'add'
            } else {
                this.listStatus = 'remove'
            }
            this.detEncoderDialog = true
        } else if (list === 'Compliance Approver') {
            if(item.is_det_compliance_approver){
                this.listStatus = 'add'
            } else {
                this.listStatus = 'remove'
            }
            this.complianceDialog = true
        } else if (list === 'Control Committee') {
            if(item.is_control_committee){
                this.listStatus = 'add'
            } else {
                this.listStatus = 'remove'
            }
            this.controlDialog = true
        } else if (list === 'SAS Committee') {
            if(item.is_sas_committee){
                this.listStatus = 'add'
            } else {
                this.listStatus = 'remove'
            }
            this.sasDialog = true
        }else if (list === 'Onboarding Head') {
            if(item.is_oh_approver){
                this.listStatus = 'add'
            } else {
                this.listStatus = 'remove'
            }
            this.ohDialog = true
        } else if (list == 'Finance Committee'){
            if(item.is_finance_committee){
                this.listStatus = 'add'
            } else {
                this.listStatus = 'remove'
            }
            this.financeDialog = true
        }
        this.selectedUser = item
        this.list = list
    },
    toggleRole (item) {
        this.roleDialog = true
        console.log(item)
        this.selectedUser = item
        this.selectedRole = item.user_role
    },
    toggleActive (item) {
        console.log(item)
        this.selectedUser = item
        if(item.is_active){
            this.actionStatus = 'activate'
        } else {
            this.actionStatus = 'deactivate'
        }
        this.statusDialog = true
    },
    cancelActive () {
        var index = this.users.findIndex( e => e.id === this.selectedUser.id)
        console.log(index)
        if(this.actionStatus === 'activate') {
            this.users[index].is_active = false
        } else {
            this.users[index].is_active = true
        }
        this.statusDialog = false
        this.selectedUser = ''
    },
    cancelList (list) {
        var index = this.users.findIndex( e => e.id === this.selectedUser.id)
        console.log(index)
        console.log(list)
        console.log(this.users[index][list])
        if(this.listStatus === 'add') {
            this.users[index][list] = 0
        } else {
            this.users[index][list] = 1
        }
        if (list === 'is_onboarding_committee') {
            this.onboardingDialog = false
        } else if (list === 'is_evaluation_committee') {
            this.evaluationDialog = false
        } else if (list === 'is_president') {
            this.presidentDialog = false
        } else if (list === 'is_validation_committee') {
            this.validationDialog = false
        }else if (list === 'is_avp') {
            this.avpDialog = false
        }else if (list === 'is_det_encoder') {
            this.detEncoderDialog = false
        }else if (list === 'is_det_compliance_approver') {
            this.complianceDialog = false
        }else if (list === 'is_control_committee') {
            this.controlDialog = false
        }else if (list === 'is_sas_committee') {
            this.sasDialog = false
        }else if (list === 'is_oh_approver') {
            this.ohDialog = false
        }else if (list === 'is_finance_committee') {
            this.financeDialog = false
        }
        this.selectedUser = ''
        this.listStatus = ''
    },
    cancelRole () {
        var index = this.users.findIndex( e => e.id === this.selectedUser.id)
        var filtered = this.usersOrig.filter( e => e.id === this.selectedUser.id)
        this.users[index].user_role = filtered[0].user_role
        this.roleDialog = false
        this.selectedUser = ''
    },
    avatarColor (fullname, ligthness = 40, saturation = 75) {
        let hash = 0

        if (fullname) {
            for (let i = 0; i < fullname.length; i++) {
            hash = fullname.charCodeAt(i) + ((hash << 5) - hash)
            }
            const hue = hash % 360
            return `background-color: hsl(${hue}, ${saturation}%, ${ligthness}%) !important`
        }
        return ''
    },
    avatarInitial (fullname)  {
        let initial = ''

        if (fullname) {
            let name = ''

            fullname = fullname.toUpperCase()

            if (fullname.indexOf(',') !== -1) {
            fullname = fullname.split(',')
            name = fullname[1]?.replace(/(^\s+|\s+$)/g, '')
            } else {
            name = fullname
            }

            initial = name.substr(0, 1)
        }
        return initial
    },
  }
}
</script>
<style scoped>
    .v-data-table.ph-nonex-datatable {
    /* background: #FFFFFF; */
    box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
    }

    .ph-nonex-sender-container,
    .ph-nonex-receiver-container {
    display: flex;
    }

    .ph-nonex-table-avatar {
    margin: auto 0;
    }

    .ph-nonex-table-names-text,
    .ph-nonex-status-text {
    margin: auto;
    margin-left: 10px;
    }

    .ph-nonex-status-circle {
    height: 10px;
    width: 10px;
    background: #f5f5f5;
    border-radius: 50%;
    margin: auto 0;
    }

    .ph-nonex-status-success {
    background: #46B746;
    }

    .ph-nonex-status-success-text {
    color: #46B746;
    }

    .ph-nonex-status-pending {
    background: #F76F34;
    }

    .ph-nonex-status-pending-text {
    color: #F76F34;
    }

    .ph-nonex-status-container {
    display: flex;
    }

    .ph-nonex-action-container {
    display: flex;
    flex-direction: row;
    }

    .ph-wu-logo {
    height: 25px;
    width: 10px;
    }

    .ph-nonex-logo {
    transform: scale(0.45);
    border-radius: 0;
    }

    .ph-fulfill-logo {
    transform: scale(0.7);
    border-radius: 0;
    }

    .ph-tooltip {
    background: #EDEDED;
    color: #000;
    border-radius: 50px;
    }

    .ph-nonex-table-names-text.text-amount {
    margin-left: 0 !important;
    }

    .text-inquire-button {
    cursor: pointer;
    }
</style>
